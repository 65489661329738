// import React, { createContext, useMemo, useContext } from "react";
// import { io } from "socket.io-client";

// const SocketContext = createContext(null);

// export const useSocket = () => {
//   const socket = useContext(SocketContext);
//   return socket;
// };

// export const SocketProvider = (props) => {
//   const socket = useMemo(() => io("localhost:5000"), {transports: ['websocket', 'polling'] });

//   return (
//     <SocketContext.Provider value={socket}>
//       {props.children}
//     </SocketContext.Provider>
//   );
// };


import React, { createContext, useMemo, useContext } from "react";
import { io } from "socket.io-client";

const SocketContext = createContext(null);

export const useSocket = () => {
  const socket = useContext(SocketContext);
  return socket;
};

export const SocketProvider = (props) => {
  // Provide an empty array if you want the socket instance to be created only once
  //const socket = useMemo(() => io("http://localhost.5000", { transports: ['websocket', 'polling'] }), []);
  const socket = useMemo(() => io("http://micropays.in/", {
    transports: ['websocket', 'polling'],  // Enable polling as fallback
    secure: true,  // Use secure WebSocket
 
  }), []);
  return (
    <SocketContext.Provider value={socket}>
      {props.children}
    </SocketContext.Provider>
  );
};
